.logo {
    opacity: 0.5;
    width: min(80%, 35em);
}
/* Scrolling Main */
main {
    font-family: 'Crimson Pro', serif;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;
}
main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.25em rgba(0,0,0,0.3);
    background-color: #251D20;
}
main::-webkit-scrollbar {
    width: 0.25em;
    background-color: #251D20;
}
main::-webkit-scrollbar-thumb {
    background-color: #251D20;
    border-right: 0.2em double #fafafa;
    border-radius: 1em;
}
p,ul,a {
    font-size: 1rem;
}
/* Content Sections */
header {
    scroll-snap-align: start;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
section {
    scroll-snap-align: center;
    z-index: 1;
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex-direction: column;
    color: #fafafa;
}
section .inner {
    margin: 0 auto;
    max-width: 960px;
    width: 80%;
}
section h1 {
    margin: 0;
    margin-top: 1em;
    font-family: 'Girassol', serif;
    font-size: 4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
section a {
    color: #FFECA1;
    text-decoration: none;
}
section a:hover {
    color: #BABABA;
}
section .text {
    width: max(20em, calc(50% - 4em));
    min-height: 50%;
    padding: 2em;
    -webkit-box-shadow: 1em 1.25em 3.5em -2em rgba(21,21,21,0.75);
    -moz-box-shadow: 1em 1.25em 3.5em -2em rgba(21,21,21,0.75);
    box-shadow: 1em 1.25em 3.5em -2em rgba(21,21,21,0.75);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 600px) {
    section .inner {
        width: calc(100% - 2em);
    }
    section .text {
        width: calc(100% - 2em);
        padding: 1em;
    }
  }
/* Story Selector */
figure {
    margin: 0;
}
.story-select {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.story-select > hr {
    height: 0.2em;
    margin: 0;
    border: none;
}
.story-select > a {
    height: calc(50% - 0.15em);
}
.story-select-item {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
#corsiaverse-text {
    width: min(25em, 80%);
    position: absolute;
    bottom: 1em;
    left: 10%;
}
#shrouded-text {
    width: min(20em, 80%);
    height: min(10em, 33vh);
    position: absolute;
    top: 1em;
    left: 10%;
}
.story-select-item-overlay {
    height: 100%;
    width: 100%;
    background: rgba(20,20,20,0.8);
    position: absolute;
}
.story-select-item-overlay.blur {
    filter: blur(1em);
    -webkit-filter: blur(1em);
}
.story-select-item-text {
    transition: filter 400ms ease;
    z-index: 1;
}
.story-select-item-text.blur {
    filter: blur(1em);
    -webkit-filter: blur(1em);
}
.story-select-background {
    transition: filter 400ms ease;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.story-select-background.blur {
    filter: blur(1em);
    -webkit-filter: blur(1em);
}
.copy-popup {
    opacity: 0;
    background: black;
    border-radius: 0.25em;
    position: absolute;
    top: 3em;
    right: 5.5em;
    padding: 0.5em 1em;
    transition: all 300ms ease-in-out;
}
.copy-popup.visible {
    opacity: 1;
}