/* Backgrounds and Textures */
.fixed-bg {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
.foreground {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
}
.bg-video {
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}