.app {
  height: 100vh;
}
nav {
  font-size: 1.1rem;
  font-family: 'Crimson Pro', serif;
  font-weight: bold;
  position: fixed;
  z-index: 10;
  right: 1em;
  top: 1em;
  width: max(60vmin, 16em);
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: white;
  z-index: 100;
}
nav a, nav span {
  margin: 0 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  color: rgba(250,250,250,1);
  height: 100%;
  font-size: 1.2rem;
  font-family: 'Crimson Pro', serif;
}
nav span:hover,a:hover {
  cursor: pointer;
  color: rgba(250,250,250,0.8);
}
.s-icon {
  height: 100%;
}
.s-icon img {
  height: 100%;
  object-fit: contain;
}
.s-icon:hover {
  opacity: 0.8;
}
.credit {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  margin: 0.5em .5em;
  font-family: 'Merriweather', serif;
  font-style: italic;
  opacity: 0.25;
}
/* COMMON ANIMATIONS */
@keyframes slowSlide {
  from {
      transform: translate3d(0,0,0);
  }
  to {
      transform: translate3d(-10%,0,0);
  }
}
@keyframes popIn {
  from {
      opacity: 0;
      transform: translate3d(0,10%,0);
  }
  to {
      opacity: 1;
      transform: translate3d(0,0,0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-iteration-count: 1;
}
/* COMMON TRANSITIONS */
.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}